import React from 'react';
import '../../App.css';


function PageNotFound() {
window.location.href = '/404.html';
   return (
  <>
  </>

  );

}
export default  PageNotFound;
